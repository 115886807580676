import { render, staticRenderFns } from "./mailVendeur.vue?vue&type=template&id=30c61dd9&scoped=true"
import script from "./mailVendeur.vue?vue&type=script&lang=js"
export * from "./mailVendeur.vue?vue&type=script&lang=js"
import style0 from "./mailVendeur.vue?vue&type=style&index=0&id=30c61dd9&prod&scoped=true&lang=scss"
import style1 from "./mailVendeur.vue?vue&type=style&index=1&id=30c61dd9&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30c61dd9",
  null
  
)

export default component.exports