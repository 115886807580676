<template>
  <v-dialog
    v-model="isOpen"
    max-width="1700px"
    hide-overlay
    persistent
    content-class="custom-vuetify-dialog-header-envoi-email"
  >
    <v-card>
      <v-card-title class="text-h6 title-modal">
        <v-label class="label-header">{{
          'Email à envoyer :  ' + computedName
        }}</v-label>
        <v-btn
          class="btn-close-header"
          icon
          @click="$emit('custumChange')"
          title="Fermer"
          color="#704ad1"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="line-hr-header"></v-divider>
      <v-card-text class="body-card center-text  mt-2">

        <div class="mail-modal-container">
          <div
            v-if="getLoaderSendMailCdg == true"
            class="loading-custom-template-block "
            style="top:-46px"
          >
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#4d4bac'"
              class="loeder"
            />
          </div>

          <div
            v-if="getLoadingMailAppelCommerciall == true"
            class="loading-custom-template-block"
          >
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#4d4bac'"
              class="loeder"
            />
          </div>
          <div class="mail-vendeur-box">
            <div class="mail-vendeur-content">
              <template-mail
                @eventMailSend="
                  dataToUse[index].updateMailSend(true);
                  $emit('eventMailSendRefresh');
                "
                :changeVendeurIndex="changeVendeurIndex"
                :dataToUse="dataToUse[index]"
                v-if="dataToUse && dataToUse[index]"
              />
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    isOpen: { required: true }
  },
  data() {
    return {
      index: 0,
      changeVendeurIndex: true
    };
  },
  computed: {
    ...mapGetters(['getLoadingMailAppelCommerciall', 'getLoaderSendMailCdg']),
    computedDisabledSend() {
      return true;
    },
    computedName() {
      return (
        this.dataToUse &&
        this.dataToUse[this.index] &&
        this.dataToUse[this.index].client
      );
    }
  },
  methods: {
    previous() {
      this.changeVendeurIndex = !this.changeVendeurIndex;
      this.index = this.index - 1;
    },
    next() {
      this.changeVendeurIndex = !this.changeVendeurIndex;
      this.index = this.index + 1;
    }
  },
  components: {
    templateMail: () => import('./templateMail.vue')
  }
};
</script>

<style scoped lang="scss">
.mail-vendeur-box {
  height: calc(100vh - 78px);
  .mail-vendeur-content {
    height: calc(100vh - 126px);
  }
}
.actionModelMailVendeur {
  .button-valide-style {
    &:active {
      height: auto;
    }
  }
}
</style>
<style lang="scss">
.send-mail-component-cdg-modal {
  .modal-header,
  .modal-body {
    padding: 0.5rem;
  }
}
</style>
